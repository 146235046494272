<script lang="ts">
	import { relatedUrlList, services, expertises, industries } from '$lib/config/home';
	import { openGraphImageUrl, domain } from '$lib/config/system';
	import { articles, blogs, feed, organizationSchema } from '$lib/config/shared';
	import { sortArticlesByUrlList } from '$lib/helpers/sort-articles.helper';
	import { serializeSchema } from '$lib/helpers/json-ld.helper';
	import type { SeoConfig } from '$lib/domain/seo-config.interface';

	import AwardsBlock from '$lib/components/shared/AwardsBlock.svelte';
	import OurClientsBlock from '$lib/components/shared/OurClientsBlock.svelte';
	import ServicesBlock from '$lib/components/shared/ServicesBlock.svelte';
	import IndustriesBlock from '$lib/components/home/IndustriesBlock.svelte';
	import ReviewsBlock from '$lib/components/home/ReviewsBlock.svelte';
	import ExpertiseBlock from '../lib/components/shared/ExpertiseBlock.svelte';

	let RelatedArticlesBlock, ContactForm;
	import('$lib/components/home/RelatedArticlesBlock.svelte').then(component => { RelatedArticlesBlock = component.default; });
	import('$lib/components/shared/ContactForm.svelte').then(component => { ContactForm = component.default; });

	const seo: SeoConfig = {
		title: 'Trusted Software Development Company | Exoft',
		description:'Exoft is a reliable software development company serving businesses from all over the globe. We can support your growth with our profound tech expertise.',
		canonical: `${domain}`,
		modifiedTime: '2023-10-10T14:04:20+00:00'
	};

	const relatedCaseStudies = articles.filter(( item ) =>[ ...relatedUrlList ].includes( item.url ));

	const relatedBlogs = blogs.filter(( item ) =>[ ...relatedUrlList ].includes( item.url ));

	const relatedArticles = sortArticlesByUrlList( relatedUrlList, [ ...relatedBlogs, ...relatedCaseStudies ] );

	const serializedOrganizationSchema = serializeSchema(organizationSchema);
</script>

<svelte:head>
	<title>{seo.title}</title>
	<link rel="canonical" href="{seo.canonical}" />
	<meta name="description" content="{seo.description}" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="{seo.title}" />
	<meta property="og:description" content="{seo.description}" />
	<meta property="og:url" content="{seo.canonical}" />
	<meta property="og:site_name" content="Exoft" />
	<meta property="article:modified_time" content="{seo.modifiedTime}" />
	<meta property="og:image" content="{openGraphImageUrl}" />
	<meta property="og:image:width" content="1772" />
	<meta property="og:image:height" content="1592" />
	<meta property="og:image:type" content="image/webp" />
	<meta property="og:image:alt" content="Exoft logo" />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:site" content="Exoft" />
	<meta name="twitter:title" content="{seo.title}" />
	<meta name="twitter:description" content="{seo.description}" />
	<meta name="twitter:image" content="{openGraphImageUrl}" />
	<link rel="preload" href="/index-background-image.webp" as="image" />

	<style>
		.top-page-bg {
			background: url(/index-background-image.webp) center/cover no-repeat;
		}
	</style>
</svelte:head>

<section>
	<div class="root flex-column align-center">
		<section class="top-page-bg">
			<div class="container top-page">
				<h1>Software development services</h1>
				<p class="h5">
					We offer end-to-end software development services to help you reach business goals — whether you need to roll out your first product, operate more efficiently, tap into new markets, or serve your customers better
				</p>
				<a href="{domain}/contact-us/" type="button" class="action-button"> Partner with us </a>
			</div>
		</section>

		<section class="no-paddings">
			<AwardsBlock/>
		</section>

		<section>
			<OurClientsBlock />
		</section>

		<section>
			<ServicesBlock
					data="{services}"
					title="{'Our services'}"
					description="{'Our custom software development company can develop a modern, reliable, and bug-free solution that would satisfy business needs and facilitate its digital transformation'}"
			/>
		</section>

		<section class="no-paddings">
			  <ExpertiseBlock
            title="{'Our expertise'}"
            description="{'As an experienced software development company, we know how to tackle every stage and all types of product creation. Whatever your request may be, you can rest assured that we will fulfill it professionally.'}"
            data="{expertises}"
			/>
		</section>

		<section>
				<IndustriesBlock
						data="{industries}"
						title="{'Focus industries'}"
				/>
		</section>

		<section class="no-paddings">
				<ReviewsBlock
				description="See what our clients think about their experience entrusting us with product development"
				data="{feed}"
				/>
		</section>
		
		{#if RelatedArticlesBlock}
			<section>
				<svelte:component
					this={RelatedArticlesBlock}
					title="{'Insights from our experience'}"
					articles="{relatedArticles}"
				/>
			</section>
		{/if}
		
		{#if ContactForm}
			<section>
				<svelte:component this={ContactForm}/>
			</section>
		{/if}
	</div>
</section>
{@html serializedOrganizationSchema}

<style lang="scss">
	@import '$styles/vars.scss';

	.root {

		h1 {
			margin-top: 120px;
		}
	}

	@media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {
		.root {
			.top-page-bg {
				background-position: 25% 0 ;
			}
		}
	}

	@media (max-width: $portrait-phone-size) {
		.root{
			.top-page-bg {
				background-position: 44% 0 ;
			}
			h1 {
				margin-top: 0;
			}
		}
	}
</style>
